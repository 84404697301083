import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "gatsby";
import buttondevelopments from "../../assets/jss/material-kit-react/buttondevelopments.jsx";
import AniLink from "gatsby-plugin-transition-link/AniLink";


function ButtonDevelopments() {
    return (
      <div>
        {/* <Link className="buttoncustom" to="/location">MORE INFORMATION</Link> */}
        <AniLink className="buttondevelopments" paintDrip to="/developments"  hex="#0385BB">
</AniLink>
      </div>
    )
  }

  export default withStyles(buttondevelopments)(ButtonDevelopments);