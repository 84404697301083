import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "gatsby";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomButton from "components/CustomButtons/CustomButton.jsx";
import ButtonCS from "components/CustomButtons/ButtonCS.jsx";
import ButtonDevelopments from "components/CustomButtons/ButtonDevelopments.jsx";
import ButtonColliery from "components/CustomButtons/ButtonColliery.jsx";
import DownloadButton from "components/CustomButtons/DownloadButton.jsx";
import SpecButton from "components/CustomButtons/SpecButton.jsx";

import Parallax from "components/Parallax/Parallax.jsx";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import SectionBasics from "./Sections/SectionBasics.jsx";
import SectionNavbars from "./Sections/SectionNavbars.jsx";
import SectionTabs from "./Sections/SectionTabs.jsx";
import SectionPills from "./Sections/SectionPills.jsx";
import SectionNotifications from "./Sections/SectionNotifications.jsx";
import SectionTypography from "./Sections/SectionTypography.jsx";
import SectionJavascript from "./Sections/SectionJavascript.jsx";
import SectionCarousel from "./Sections/SectionCarousel.jsx";
import SectionCompletedExamples from "./Sections/SectionCompletedExamples.jsx";
import SectionLogin from "./Sections/SectionLogin.jsx";
import SectionExamples from "./Sections/SectionExamples.jsx";
import SectionDownload from "./Sections/SectionDownload.jsx";
import ProductSection from "./Sections/ProductSection.jsx";

import portrait from "../../assets/img/portrait2.png";
import specshome from "../../assets/img/specshome.png";
import csimg from "../../assets/img/CaseStudyHeader.jpg";
import developmentsimg from "../../assets/img/Overhead.jpg";
import footimg from "../../assets/img/footimg.png";
import image from "../../assets/img/footimg.png";
import colliery from "../../assets/img/colliery.png";

import newsStyle from "assets/jss/material-kit-react/views/newsPage.jsx";

class NewsPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          brand="Material Kit React"
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/ResearchHeader.jpg")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <div className={classes.brand}>
                <div className={classes.intro}>
                  Research
                  </div>
                  
                  <div className={classes.subtitle}>
                  The work we undertake to ensure we meet our clients’ objectives

                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.mainwrap}>
          {/* <ProductSection /> */}
          <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
          <div className={classes.preheading}>
          Research
                  </div>
                 
                <div className={classes.parawrap}>
                <p className={classes.paratext}>
                We recently commissioned Savills to undertake a detailed analysis of the South Wales Industrials &amp; Logistics market. 
                <br/>
                <br/>
                
                The research report covers:
                <br/>
                <br/>
                <div className={classes.listwrap}>
                <ul className={classes.ulist}>
                  <li>Changes in geographical modelling </li>
                  <li>The impact of online retail</li>
                  <li>Other structural drivers and their effect over the last decade</li>
                  <li>Warehousing capacity considerations</li>
                </ul>
                </div>
                <br/>
                <br/>
                A copy of the report may be made available upon request, please contact us.
                </p>
            
              </div>
                
            </GridItem>
            </GridContainer>

            </div>  
          </div>

        <div className={classNames(classes.mainlinks, classes.mainRaisedlinks)}>

            <div className={classes.mainwraplinks}>
            <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
          <div className={classes.imagewrap}>
          <img alt="..." src={developmentsimg} className={classes.homeimage} />
          </div>
          <div className={classes.parawraplinks}>
          <ButtonDevelopments/>
                <p className={classes.paratextlinks}>
                Ongoing developments
                </p>
                
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
          <div className={classes.imagewrap}>
          <img alt="..." src={csimg} className={classes.homeimage} />
          </div>
          <div className={classes.parawraplinks}>
          <ButtonCS/>
                <p className={classes.paratextlinks}>
                Examples of our previous projects
                </p>
            
              </div>
            </GridItem>
            
            </GridContainer>

            </div>   
        </div>

        <Footer />
      </div>
    );
  }
}

export default withStyles(newsStyle)(NewsPage);