import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "gatsby";
import buttoncs from "../../assets/jss/material-kit-react/buttoncs.jsx";
import AniLink from "gatsby-plugin-transition-link/AniLink";


function ButtonCS() {
    return (
      <div>
        {/* <Link className="buttoncustom" to="/location">MORE INFORMATION</Link> */}
        <AniLink className="buttoncs" paintDrip to="/case-studies"  hex="#0385BB">
</AniLink>
      </div>
    )
  }

  export default withStyles(buttoncs)(ButtonCS);